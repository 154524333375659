import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a7e33e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      src: _ctx.imageUrl,
      style: {"display":"none"},
      onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args))),
      onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleImageLoad && _ctx.handleImageLoad(...args))),
      alt: "Image"
    }, null, 40, _hoisted_1),
    (_ctx.isImageLoaded)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: {"width":"100px"},
          src: _ctx.imageUrl,
          alt: "Image"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}