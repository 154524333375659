import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "header-search-field" }
const _hoisted_2 = {
  class: "header-search-icon",
  style: {}
}
const _hoisted_3 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_4 = ["fill"]
const _hoisted_5 = {
  key: 0,
  class: "header-search-button-group"
}
const _hoisted_6 = {
  key: 1,
  class: "header-search-results"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "search-key-hint" }
const _hoisted_9 = { class: "search-key" }
const _hoisted_10 = { class: "search-key-hint" }
const _hoisted_11 = { class: "search-result-description" }
const _hoisted_12 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("input", {
        ref: "searchInput",
        onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
        onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.bluroutHandler && _ctx.bluroutHandler(...args))),
        onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
        class: "header-search-input",
        placeholder: "Search for symbols or companies"
      }, null, 544),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock("svg", _hoisted_3, [
          _createElementVNode("path", {
            d: "M17.8395 16.4605L14.1641 12.7852C15.0489 11.6072 15.5266 10.1733 15.525 8.7C15.525 4.93672 12.4633 1.875 8.7 1.875C4.93672 1.875 1.875 4.93672 1.875 8.7C1.875 12.4633 4.93672 15.525 8.7 15.525C10.1733 15.5266 11.6072 15.0489 12.7852 14.1641L16.4605 17.8395C16.6466 18.0058 16.8893 18.0945 17.1387 18.0876C17.3881 18.0806 17.6255 17.9784 17.8019 17.8019C17.9784 17.6255 18.0806 17.3881 18.0876 17.1387C18.0945 16.8893 18.0058 16.6466 17.8395 16.4605ZM3.825 8.7C3.825 7.73582 4.11091 6.79329 4.64659 5.9916C5.18226 5.18991 5.94363 4.56506 6.83442 4.19609C7.72521 3.82711 8.70541 3.73057 9.65107 3.91867C10.5967 4.10678 11.4654 4.57107 12.1471 5.25285C12.8289 5.93464 13.2932 6.80328 13.4813 7.74894C13.6694 8.69459 13.5729 9.67479 13.2039 10.5656C12.8349 11.4564 12.2101 12.2177 11.4084 12.7534C10.6067 13.2891 9.66418 13.575 8.7 13.575C7.40755 13.5735 6.16847 13.0593 5.25457 12.1454C4.34066 11.2315 3.82655 9.99246 3.825 8.7Z",
            fill: _ctx.isBlur?'white':'#808080'
          }, null, 8, _hoisted_4)
        ]))
      ]),
      (_ctx.isBlur)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearSearchKey && _ctx.clearSearchKey(...args))),
              class: "header-search-clear-button"
            }, " CLEAR "),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.searchHandler && _ctx.searchHandler(...args))),
              class: "header-search-button"
            }, " SEARCH ")
          ]))
        : _createCommentVNode("", true),
      (_ctx.isBlur && _ctx.searchResults)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults.slice(0, 5), (result, index) => {
              return (_openBlock(), _createElementBlock("button", {
                onClick: ($event: any) => (_ctx.clickHandler(result?.symbol)),
                class: "autocomplete-result",
                key: index
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.splitWords(result?.symbol, _ctx.searchKey)[0]), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.splitWords(result.symbol, _ctx.searchKey)[1]), 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.splitWords(result.symbol, _ctx.searchKey)[2]), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(result.name), 1)
                ])
              ], 8, _hoisted_7))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}