import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "company-summary" }
const _hoisted_2 = { class: "company-summary-left" }
const _hoisted_3 = { class: "company-summary-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FinancialPerformance = _resolveComponent("FinancialPerformance")!
  const _component_Notes = _resolveComponent("Notes")!
  const _component_Calculator = _resolveComponent("Calculator")!
  const _component_Valuation = _resolveComponent("Valuation")!
  const _component_Summary = _resolveComponent("Summary")!
  const _component_Financials = _resolveComponent("Financials")!
  const _component_ValueEstimate = _resolveComponent("ValueEstimate")!
  const _component_Reports = _resolveComponent("Reports")!
  const _component_Container = _resolveComponent("Container")!
  const _component_ForecastModal = _resolveComponent("ForecastModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Container, null, {
      default: _withCtx(() => [
        _createVNode(_component_Header, { company: _ctx.company }, null, 8, ["company"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FinancialPerformance, { company: _ctx.company }, null, 8, ["company"]),
            _createVNode(_component_Notes)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Calculator, { company: _ctx.company }, null, 8, ["company"]),
            _createVNode(_component_Valuation, { company: _ctx.company }, null, 8, ["company"])
          ])
        ]),
        _createVNode(_component_Summary, { company: _ctx.company }, null, 8, ["company"]),
        _createVNode(_component_Financials, {
          id: "financials",
          company: _ctx.company
        }, null, 8, ["company"]),
        _createVNode(_component_ValueEstimate, { company: _ctx.company }, null, 8, ["company"]),
        _createVNode(_component_Reports, { company: _ctx.company }, null, 8, ["company"])
      ]),
      _: 1
    }),
    _createVNode(_component_ForecastModal, { onSave: _ctx.createForecast }, null, 8, ["onSave"])
  ], 64))
}