import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createBlock(_component_Bar, {
    data: _ctx.chartData,
    options: _ctx.options,
    plugins: [_ctx.ChartDataLabels, _ctx.drawShape]
  }, null, 8, ["data", "options", "plugins"]))
}