import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Preloader = _resolveComponent("Preloader")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_NewListModal = _resolveComponent("NewListModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Preloader),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createVNode(_component_router_view),
      _createVNode(_component_Footer),
      _createVNode(_component_NewListModal)
    ])
  ], 64))
}