import { vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
    ref: "textarea",
    onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.calculateHeight && _ctx.calculateHeight(...args))),
    style: _normalizeStyle({
      height: _ctx.textareaHeight,
    })
  }, null, 36)), [
    [_vModelText, _ctx.innerValue]
  ])
}