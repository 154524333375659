import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-spacer"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.separate)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("tr", null, [
      _createElementVNode("td", {
        class: _normalizeClass(["table-row", {'ceo-row': _ctx.name === 'CEO'}])
      }, [
        (_ctx.showCounter && _ctx.index)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString((_ctx.index + 1).toString().padStart(2, '0')), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
      ], 2),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.comments)
        ? (_openBlock(), _createElementBlock("td", {
            key: 0,
            class: _normalizeClass(["table-comment", {'ceo-row': _ctx.name==='CEO'}])
          }, null, 2))
        : _createCommentVNode("", true)
    ])
  ], 64))
}