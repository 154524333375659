import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "summary-logo" }
const _hoisted_2 = { class: "summary-stats" }
const _hoisted_3 = { class: "summary-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageLoader = _resolveComponent("ImageLoader")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "summary" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ImageLoader, {
          imageUrl: _ctx.logoUrl,
          maxWidth: 100
        }, null, 8, ["imageUrl"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, [
          _createTextVNode("Founded: "),
          _createElementVNode("span", null, _toDisplayString(_ctx.company.data.founded.toLocaleString()), 1)
        ]),
        _createElementVNode("p", null, [
          _createTextVNode("Employees: "),
          _createElementVNode("span", null, _toDisplayString(_ctx.company.data.employees.toLocaleString()), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, [
          _createTextVNode(" Business Summary: "),
          _createElementVNode("span", null, _toDisplayString(_ctx.company.data.summary), 1)
        ])
      ])
    ]),
    _: 1
  }))
}