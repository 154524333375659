import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "financials" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      company: _ctx.company,
      ref: "controls"
    }, null, 8, ["company"]),
    _createVNode(_component_Table, {
      ref: "table",
      company: _ctx.company,
      forecast: _ctx.controls ? _ctx.controls.selectedForecast : _ctx.forecastControl.AVERAGES,
      class: _normalizeClass({
        'financials-disabled': !_ctx.controls || _ctx.controls.selectedType !== _ctx.typeControl.TABLE
      })
    }, null, 8, ["company", "forecast", "class"]),
    _createVNode(_component_Chart, {
      company: _ctx.company,
      data: _ctx.table && _ctx.table.tableData,
      class: _normalizeClass({
        'financials-disabled': !_ctx.controls || _ctx.controls.selectedType !== _ctx.typeControl.CHART
      })
    }, null, 8, ["company", "data", "class"])
  ]))
}