import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "card-loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSpinner = _resolveComponent("VueSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_VueSpinner, {
            color: "var(--theme-link-color)",
            size: "30"
          })
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}